import { http, HttpResponse } from "msw";
import Box from "@mui/material/Box";
import Performance from "./";
import { MockedProvider } from "@apollo/client/testing";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { GET_CLIENTS_QUERY } from "components/PerformanceTable/PerformanceTable";
import { expect, screen, userEvent, waitFor, within } from "@storybook/test";
import FeaturesProvider, { useFeatures } from "providers/FeaturesProvider";
import { setStorage } from "lib/utils";
import { GET_MARKETING_PLATFORMS_QUERY } from "components/PerformanceTable/SpendDataValidity";
import TimezoneProvider from "lib/TimezoneProvider";
import ScopeProvider from "lib/ScopeProvider";
import { GET_SAVED_REPORTS_QUERY, GET_SCHEDULED_EXPORTS_QUERY } from "components/PerformanceTable/data/queries";


export default {
  title: "pages/Performance",
  component: Performance,
};

const Template = (args, { parameters }) => {
  const { features } = useFeatures()
  setStorage('PerformanceSidebar.open', parameters.sidebarOpen)

  return (
    <MockedProvider mocks={parameters.mocks} addTypename={true}>
      <ScopeProvider>
        <Box sx={{ minHeight: "2000px" }}>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <FeaturesProvider
              features={{
                ...features,
                performance_table_column_menu: true,
                performance_table_media_columns: true,
              }}
            >
              <TimezoneProvider>
                <Performance {...args} />
              </TimezoneProvider>
            </FeaturesProvider>
          </QueryParamProvider>
        </Box>
      </ScopeProvider>
    </MockedProvider>
  );
};

export const Default = {
  render: Template,
  args: {
    currentOrganization: {
      type: "organization",
      id: "1",
      attributes: {
        name: "Cygnus Education",
      },
      relationships: {
        client: {
          data: null,
        },
        vendor: {
          data: {
            type: "vendor",
            id: "c99af147-405e-4390-b16e-411e3a858faa",
          },
        },
      },
    },
    currentUser: {
      type: "user",
      id: "1",
      attributes: {
        permitGlobalAdmin: true,
      },
    },
  },
  parameters: {
    sidebarOpen: false,
    reactRouter: {
      routePath: "/performance/*",
      browserPath: "/performance/quality",
    },
    orbit: {
      user: {
        permitGlobalAdmin: true,
      },
    },
    msw: {
      handlers: [
        http.get("/api/v1/contracts/*client-campaigns*", (_request, _params, _cookies) => {
          return HttpResponse.json({
            data: [
            {
              id: "33",
              type: "client-campaigns",
              attributes: {
                name: "Health",
                "campaign-type": "exclusive",
                "billable-lead-count": 808,
                "smart-score": "green",
                "public-id": "00003820",
              },
              relationships: {
                contract: {
                  data: {
                    id: "45",
                    type: "contracts",
                  },
                },
                "program-group": {
                  data: {
                    id: "96",
                    type: "program-groups",
                  },
                },
              },
            },
            {
              id: "34",
              type: "client-campaigns",
              attributes: {
                name: "Two Line Campaign Title Example",
                "campaign-type": "shared",
                "billable-lead-count": 111,
                "smart-score": "yellow",
                "public-id": "29300280",
              },
              relationships: {
                contract: {
                  data: {
                    id: "45",
                    type: "contracts",
                  },
                },
                "program-group": {
                  data: {
                    id: "97",
                    type: "program-groups",
                  },
                },
              },
            },
              {
                id: "135",
                type: "client-campaigns",
                attributes: {
                  name: "Education",
                  "campaign-type": "exclusive",
                  "billable-lead-count": 634,
                  "smart-score": "red",
                  "public-id": "00003820",
                },
                relationships: {
                  contract: {
                    data: {
                      id: "45",
                      type: "contracts",
                    },
                  },
                  "program-group": {
                    data: {
                      id: "96",
                      type: "program-groups",
                    },
                  },
                },
              },
            {
              id: "136",
              type: "client-campaigns",
              attributes: {
                name: "Liberal Arts",
                "campaign-type": "exclusive",
                "billable-lead-count": 105,
                "smart-score": null,
                "public-id": "29300280",
              },
              relationships: {
                contract: {
                  data: {
                    id: "45",
                    type: "contracts",
                  },
                },
                "program-group": {
                  data: {
                    id: "98",
                    type: "program-groups",
                  },
                },
              },
            },
            ],
          });
        }),
        http.get("/api/v1/program-groups", (_request, _params, _cookies) => {
          return HttpResponse.json({
            data: [
            {
              id: "96",
              type: "program-groups",
              attributes: {
                id: 96,
                "client-id": 123,
                description: "Health",
              },
              relationships: {
                client: { data: { id: "123", type: "clients" } },
              },
            },
            {
              id: "97",
              type: "program-groups",
              attributes: {
                id: 97,
                "client-id": 123,
                description: "Education",
              },
              relationships: {
                client: { data: { id: "123", type: "clients" } },
              },
            },
              {
                id: "98",
                type: "program-groups",
                attributes: {
                  id: 98,
                  "client-id": 123,
                  description: "Liberal Arts",
                },
                relationships: {
                  client: { data: { id: "123", type: "clients" } },
                },
              },
            ],
          });
        }),
        http.get("/api/v1/sources", (_request, _params, _cookies) => {
          return HttpResponse.json([
            "asdf",
            "facebook",
            "fb",
            "fbl",
            "fb-rd",
            "foo",
            "go",
            "ig",
            "ju",
            "li",
            "lil",
            "or",
            "pn",
            "qu",
            "rd",
            "sn",
            "test",
            "test01",
            "test1",
            "tester",
            "testing",
            "testing123",
            "testwh",
            "tt",
            "yt",
          ]);
        }),
        http.get("/api/v1/vendors", (_request, _params, _cookies) => {
          return HttpResponse.json({
            data: [
            {
              id: "1",
              type: "vendors",
              attributes: {
                id: 1,
                name: "Cygnus Education",
                "created-at": "2021-01-25T01:26:50.313Z",
                "updated-at": "2021-01-25T01:26:50.313Z",
                "contact-name": null,
                "contact-email": null,
                "street-address": null,
                city: null,
                state: null,
                "zip-code": null,
                phone: null,
                "external-config": {},
                "internal-for-client-id": null,
              },
              relationships: { organization: { data: { id: "1", type: "organizations" } } },
            },
              {
                id: "3",
                type: "vendors",
                attributes: {
                  id: 3,
                  name: "EducationStation",
                  "created-at": "2022-01-07T12:21:14.095Z",
                  "updated-at": "2022-01-07T12:21:14.095Z",
                  "contact-name": null,
                  "contact-email": null,
                  "street-address": null,
                  city: null,
                  state: null,
                  "zip-code": null,
                  phone: null,
                  "external-config": {},
                  "internal-for-client-id": null,
                },
                relationships: { organization: { data: { id: "6", type: "organizations" } } },
              },
              {
                id: "2",
                type: "vendors",
                attributes: {
                  id: 2,
                  name: "Zovio Vendor 1",
                  "created-at": "2021-12-10T16:18:13.031Z",
                  "updated-at": "2021-12-10T16:18:13.031Z",
                  "contact-name": "ZovioVendor1",
                  "contact-email": "zoviovendor1@othervendor.com",
                  "street-address": "123 Main St",
                  city: "City",
                  state: "KS",
                  "zip-code": "19072",
                  phone: "(215) 555-5555",
                  "external-config": {},
                  "internal-for-client-id": null,
                },
                relationships: { organization: { data: null } },
              },
            ],
          });
        }),
        http.get("/api/v1/degree-programs*", (_request, _params, _cookies) => {
          return HttpResponse.json({
            data: [
            {
              id: "286",
              type: "degree-programs",
              attributes: { name: "" },
              relationships: {
                client: { data: { id: "135", type: "clients" } },
                "program-group": {
                  data: { id: "61", type: "program-groups" },
                },
              },
            },
            {
              id: "195",
              type: "degree-programs",
              attributes: { name: "Accounting Associate's Degree" },
              relationships: {
                client: { data: { id: "94", type: "clients" } },
                "program-group": {
                  data: { id: "153", type: "program-groups" },
                },
              },
            },
            ],
          });
        }),
        http.get("/api/v1/landing-pages", (_request, _params, _cookies) => {
          return HttpResponse.json({
            data: [],
          });
        }),
        http.get("/api/v1/client-campaigns", (_request, _params, _cookies) => {
          return HttpResponse.json({
            data: [],
          });
        }),
        http.get("/api/v1/ad-campaigns", (_request, _params, _cookies) => {
          return HttpResponse.json({
            data: [],
          });
        }),
        http.get("/api/v1/ad-sets", (_request, _params, _cookies) => {
          return HttpResponse.json({
            data: [],
          });
        }),
        http.post("/api/v1/performance", (_request, _params, _cookies) => {
          return HttpResponse.json({
            data: [
            {
              client_id: 123,
              complyed_creative_id: 1111,
              lead_count: 3,
              pending_lead_count: 0,
              filtered_lead_count: 1,
              rejected_lead_count: 0,
              error_lead_count: 1,
              returned_lead_count: 0,
              good_lead_count: 1,
              contacted_lead_count: 0,
              interviewed_lead_count: 0,
              applied_lead_count: 0,
              enrolled_lead_count: 0,
              started_lead_count: 0,
              class_passed_lead_count: 0,
              billable_lead_count: 1,
              total_cost_in_cents: 0,
              id: null,
            },
              {
                client_id: 125,
                complyed_creative_id: 22222,
                lead_count: 4,
                pending_lead_count: 0,
                filtered_lead_count: 2,
                rejected_lead_count: 0,
                error_lead_count: 2,
                returned_lead_count: 0,
                good_lead_count: 0,
                contacted_lead_count: 0,
                interviewed_lead_count: 0,
                applied_lead_count: 0,
                enrolled_lead_count: 0,
                started_lead_count: 0,
                class_passed_lead_count: 0,
                billable_lead_count: 1,
                total_cost_in_cents: 0,
                id: null,
              },
            ],
          });
        }),
        http.get("/api/v1/media", (_request, _params, _cookies) => {
          return HttpResponse.json({
            data: [],
          });
        }),
      ],
    },
    mocks: [
      {
        request: {
          query: GET_CLIENTS_QUERY,
        },
        result: {
          data: {
            clients: [
              {
                id: 123,
                name: "Hover Over My Creative ID Column College",
                internalName: "Hover Over My Creative ID Column College",
                showDegreeProgramChanges: false,
                fields: [],
              },
              {
                id: 125,
                name: "College University",
                internalName: "College University",
                showDegreeProgramChanges: false,
                fields: [],
              },
            ],
          },
        },
      },
      {
        request: {
          query: GET_SAVED_REPORTS_QUERY,
          variables: {
            userId: 'me',
          },
        },
        result: {
          data: {
            savedReports: [
            ],
          },
        },
        maxUsageCount: Number.POSITIVE_INFINITY,
      },
      {
        request: {
          query: GET_MARKETING_PLATFORMS_QUERY,
        },
        result: {
          data: {
            marketingPlatforms: [
              {
                id: "3",
                name: "Facebook",
                type: "ConnectedService::MarketingPlatform::Facebook",
                validFrom: "2020-01-01 05:00:00 UTC",
                lastSyncedAt: "2021-01-02 22:00:00 UTC",
                __typename: "MarketingPlatform",
              },
              {
                id: "69",
                name: "Tiktok",
                type: "ConnectedService::MarketingPlatform::Tiktok",
                validFrom: "2020-01-01 05:00:00 UTC",
                lastSyncedAt: "2021-01-03 22:00:00 UTC",
                __typename: "MarketingPlatform",
              },
              {
                id: "70",
                name: "Snapchat",
                type: "ConnectedService::MarketingPlatform::Snapchat",
                validFrom: "2019-01-01 05:00:00 UTC",
                lastSyncedAt: "2020-12-03 22:00:00 UTC",
                __typename: "MarketingPlatform",
              },
            ],
          },
        },
        maxUsageCount: Number.POSITIVE_INFINITY,
      },
    ],
  }
}

export const WithCreativePreview = {
  ...Default,
  play: async ({ canvasElement }) => {
    await waitFor(async () => {
      userEvent.hover(
        within(canvasElement).getByTestId("TableChartOutlinedIcon"),
      );
    });

    userEvent.click(await screen.findByLabelText("Creative ID"));
    userEvent.unhover(await screen.findByLabelText("Creative ID"));

    const creativePreviewCell = await within(canvasElement).findByText("1111");
    await waitFor(async () => {
      userEvent.pointer({ target: creativePreviewCell, coords: { clientX: 10, clientY: 10 }});
    });
  }
}

// Unable to have the snapshot capture the cell in its hover state
// Instead the error state is present and visible with manual hover
export const WithCreativePreviewError = {
  ...WithCreativePreview,
  play: async (context) => {
    await WithCreativePreview.play(context);
    userEvent.click(await within(context.canvasElement).findByText("1111"));

    const theCell = within(context.canvasElement).getByRole("cell", { name: "1111" });
    userEvent.unhover(theCell);
  }
}

export const ColumnSettings = {
  ...Default,
  play: async ({ canvasElement }) => {
    // Starts querying the component from its root
    const canvas = within(canvasElement);
    await waitFor(async () => {
      userEvent.hover(canvas.getByText("Good Leads"));
    });
    userEvent.click(canvas.getAllByLabelText("Column Settings")[2]);
  }
}

export const GroupingColumnFilter = Template.bind({});
GroupingColumnFilter.args = {
  ...Default.args,
};
GroupingColumnFilter.parameters = {
  ...Default.parameters,
};

async function openColumnSettings(canvas, columnName) {
  const tableCell = await canvas.findByRole("columnheader", { name: columnName })
  userEvent.hover(tableCell)
  userEvent.click(await within(tableCell).findByLabelText("Column Settings"));
}

async function openFilterDialog(canvas, columnName) {
  openColumnSettings(canvas, columnName)
  userEvent.click(await screen.findByText("Filter column"));
  return await screen.findByRole("dialog")
}

GroupingColumnFilter.play = async ({ canvasElement }) => {
  // wait for table to load before getting the table
  const canvas = within(canvasElement);
  const filterDialog = await openFilterDialog(canvas, "Client")
  userEvent.click(within(filterDialog).getByLabelText("Filter By"));
  userEvent.click(await screen.findByText("Does not contain"));
  const filterValueOne = await within(filterDialog).findByRole("textbox", { name: "Value" })
  await userEvent.type(filterValueOne, "hover");
  userEvent.click(await within(filterDialog).findByText("Apply"));
};

export const ColumnSummarySingleValue = {
  ...Default,
  play: async ({ canvasElement }) => {
    const canvas = within(canvasElement);
    const filterDialog = await openFilterDialog(canvas, "Good Leads")
    userEvent.click(await within(filterDialog).findByLabelText("Filter By"));
    userEvent.click(await screen.findByText("Greater than"));
    const filterValueOne = await within(filterDialog).findByRole("textbox", { name: "Value" })
    await userEvent.type(filterValueOne, "0");
    userEvent.click(await within(filterDialog).findByText("Apply"));
  }
}

export const ColumnSummaryDoubleValue = {
  ...Default,
  play: async ({ canvasElement }) => {
    const canvas = within(canvasElement);
    const filterDialog = await openFilterDialog(canvas, "Good Leads")
    userEvent.click(await within(filterDialog).findByLabelText("Filter By"));
    userEvent.click(await screen.findByText("Is between"));
    const [filterValueOne, filterValueTwo] = await within(filterDialog).findAllByRole("textbox", { name: "Value" })
    await userEvent.type(filterValueOne, "0");
    await userEvent.type(filterValueTwo, "1000");
    userEvent.click(await within(filterDialog).findByText("Apply"));
  }
}

export const Subtotals = {
  ...Default,
  play: async ({ canvasElement }) => {
    const canvas = within(canvasElement);
    await openColumnSettings(canvas, "Client")
    userEvent.click(await screen.findByText("Subtotal"));
  }
}

export const DataValidity = {
  ...Default,
  play: async ({ canvasElement }) => {
    const canvas = within(canvasElement);
    await waitFor(async () => {
      await userEvent.hover(canvas.getByTestId("AnalyticsOutlinedIcon"));
    });
    userEvent.click(screen.getByText("Spend"));
    await userEvent.keyboard("{Escape}");
    await waitFor(async () => {
      await userEvent.hover(canvas.getByTestId("WarningAmberIcon"));
    });
  }
}

export const SidebarExpanded = {
  ...Default,
  parameters: {
    ...Default.parameters,
    sidebarOpen: true,
  }
}

export const SavedReports = {
  ...SidebarExpanded,
  parameters: {
    ...SidebarExpanded.parameters,
    mocks: [
      ...Default.parameters.mocks.filter(mock => mock.request.query !== GET_SAVED_REPORTS_QUERY && mock.request.query !== GET_SCHEDULED_EXPORTS_QUERY),
      {
        request: {
          query: GET_SAVED_REPORTS_QUERY,
          variables: {
            userId: 'me',
          },
        },
        result: {
          data: {
            savedReports: [
              {
                __typename: 'SavedReport',
                id: '1',
                name: 'Daily Report',
                userId: 'me',
                reportConfig: {
                  tableSettings: {
                    columnFilters: [
                      {
                        field: 'filteredLeadCount',
                        operator: 'greater-than',
                        value: ['1'],
                      },
                    ],
                    reportingMode: 'COHORT',
                    rowFilters: [
                      {
                        field: 'source',
                        values: ['facebook'],
                      },
                    ],
                    showAdjusted: false,
                    sortColumn: 'clientId',
                    sortOrder: 'ASC',
                    subtotalColumns: [],
                    visibleColumns: ['clientId', 'billableLeadCount', 'filteredLeadCount'],
                  },
                  timeRange: {
                    relativeTimeRange: {
                      label: 'LAST_YEAR',
                    },
                    absoluteTimeRange: null,
                  },
                },
              },
            ],
          },
        },
        maxUsageCount: Number.POSITIVE_INFINITY,
      },
      {
        request: {
          query: GET_SCHEDULED_EXPORTS_QUERY,
          variables: {
            userId: 'me',
          },
        },
        result: {
          data: {
            scheduledExports: [
              {
                id: '1',
                filename: 'TestReport1.csv',
                frequency: 'daily',
                weekCount: null,
                dayOfWeek: null,
                dayOfMonth: null,
                recipients: ['me@cygnuseducation.com', 'other@cygnuseducation.com', 'itsanotherone@cygnuseducation.com'],
                timeOfDay: '2000-01-01T19:03:00.000Z',
                savedReport: {
                  __typename: 'SavedReport',
                  id: '1',
                  name: 'Daily Report',
                  userId: 'me',
                  reportConfig: {
                    tableSettings: {
                      columnFilters: [
                        {
                          field: 'filteredLeadCount',
                          operator: 'greater-than',
                          value: ['1'],
                        },
                      ],
                      reportingMode: 'COHORT',
                      rowFilters: [
                        {
                          field: 'source',
                          values: ['facebook'],
                        },
                      ],
                      showAdjusted: false,
                      sortColumn: 'clientId',
                      sortOrder: 'ASC',
                      subtotalColumns: [],
                      visibleColumns: ['clientId', 'billableLeadCount', 'filteredLeadCount'],
                    },
                    timeRange: {
                      relativeTimeRange: {
                        label: 'LAST_YEAR',
                      },
                      absoluteTimeRange: null,
                    },
                  },
                },
                __typename: 'ScheduledExport',
              },
              {
                id: '2',
                filename: 'TestReport2.csv',
                frequency: 'workdays',
                weekCount: null,
                dayOfWeek: null,
                dayOfMonth: null,
                recipients: ['me@aol.com'],
                timeOfDay: '2000-01-01T05:12:00.000Z',
                savedReport: {
                  __typename: 'SavedReport',
                  id: '1',
                  name: 'Daily Report',
                  userId: 'me',
                  reportConfig: {
                    tableSettings: {
                      columnFilters: [
                        {
                          field: 'filteredLeadCount',
                          operator: 'greater-than',
                          value: ['1'],
                        },
                      ],
                      reportingMode: 'COHORT',
                      rowFilters: [
                        {
                          field: 'source',
                          values: ['facebook'],
                        },
                      ],
                      showAdjusted: false,
                      sortColumn: 'clientId',
                      sortOrder: 'ASC',
                      subtotalColumns: [],
                      visibleColumns: ['clientId', 'billableLeadCount', 'filteredLeadCount'],
                    },
                    timeRange: {
                      relativeTimeRange: {
                        label: 'LAST_YEAR',
                      },
                      absoluteTimeRange: null,
                    },
                  },
                },
                __typename: 'ScheduledExport',
              },
              {
                id: '3',
                filename: 'TestReport3.csv',
                frequency: 'weekly',
                weekCount: 1,
                dayOfWeek: 'friday',
                dayOfMonth: null,
                recipients: ['me@cygnuseducation.com', 'other@cygnuseducation.com'],
                timeOfDay: '2000-01-01T19:03:00.000Z',
                savedReport: {
                  __typename: 'SavedReport',
                  id: '1',
                  name: 'Daily Report',
                  userId: 'me',
                  reportConfig: {
                    tableSettings: {
                      columnFilters: [
                        {
                          field: 'filteredLeadCount',
                          operator: 'greater-than',
                          value: ['1'],
                        },
                      ],
                      reportingMode: 'COHORT',
                      rowFilters: [
                        {
                          field: 'source',
                          values: ['facebook'],
                        },
                      ],
                      showAdjusted: false,
                      sortColumn: 'clientId',
                      sortOrder: 'ASC',
                      subtotalColumns: [],
                      visibleColumns: ['clientId', 'billableLeadCount', 'filteredLeadCount'],
                    },
                    timeRange: {
                      relativeTimeRange: {
                        label: 'LAST_YEAR',
                      },
                      absoluteTimeRange: null,
                    },
                  },
                },
                __typename: 'ScheduledExport',
              },
              {
                id: '4',
                filename: 'TestReport4.csv',
                frequency: 'weekly',
                weekCount: 2,
                dayOfWeek: 'tuesday',
                dayOfMonth: null,
                recipients: ['me@cygnuseducation.com', 'other@cygnuseducation.com'],
                timeOfDay: '2000-01-01T19:03:00.000Z',
                savedReport: {
                  __typename: 'SavedReport',
                  id: '1',
                  name: 'Daily Report',
                  userId: 'me',
                  reportConfig: {
                    tableSettings: {
                      columnFilters: [
                        {
                          field: 'filteredLeadCount',
                          operator: 'greater-than',
                          value: ['1'],
                        },
                      ],
                      reportingMode: 'COHORT',
                      rowFilters: [
                        {
                          field: 'source',
                          values: ['facebook'],
                        },
                      ],
                      showAdjusted: false,
                      sortColumn: 'clientId',
                      sortOrder: 'ASC',
                      subtotalColumns: [],
                      visibleColumns: ['clientId', 'billableLeadCount', 'filteredLeadCount'],
                    },
                    timeRange: {
                      relativeTimeRange: {
                        label: 'LAST_YEAR',
                      },
                      absoluteTimeRange: null,
                    },
                  },
                },
                __typename: 'ScheduledExport',
              },
              {
                id: '5',
                filename: 'TestReport5.csv',
                frequency: 'monthly',
                weekCount: null,
                dayOfWeek: null,
                dayOfMonth: 15,
                recipients: ['itsanotherone@cygnuseducation.com'],
                timeOfDay: '2000-01-01T12:37:00.000Z',
                savedReport: {
                  __typename: 'SavedReport',
                  id: '1',
                  name: 'Daily Report',
                  userId: 'me',
                  reportConfig: {
                    tableSettings: {
                      columnFilters: [
                        {
                          field: 'filteredLeadCount',
                          operator: 'greater-than',
                          value: ['1'],
                        },
                      ],
                      reportingMode: 'COHORT',
                      rowFilters: [
                        {
                          field: 'source',
                          values: ['facebook'],
                        },
                      ],
                      showAdjusted: false,
                      sortColumn: 'clientId',
                      sortOrder: 'ASC',
                      subtotalColumns: [],
                      visibleColumns: ['clientId', 'billableLeadCount', 'filteredLeadCount'],
                    },
                    timeRange: {
                      relativeTimeRange: {
                        label: 'LAST_YEAR',
                      },
                      absoluteTimeRange: null,
                    },
                  },
                },
                __typename: 'ScheduledExport',
              },
            ],
          },
        },
        maxUsageCount: Number.POSITIVE_INFINITY,
      }
    ],
  },
  play: async ({ canvasElement }) => {
    const canvas = within(canvasElement);
    userEvent.click(await canvas.findByLabelText("Daily Report"));
  }
}

export const SavedReportMenuNoReportSelected = {
  ...SavedReports,
  play: async ({ step }) => {
    await step('Without selecting a saved report, open the saved report menu', async() => {
      userEvent.click(await screen.findByLabelText("Saved Reports Menu"))
    })

    const savedReportMenu = await screen.findByRole('menu')

    await step('Check the enabled/disabled state of menu items', async() => {
      expect(within(savedReportMenu).getByRole('menuitem', {
        name: 'Save as New Report'
      })).not.toHaveAttribute('aria-disabled', 'true')
      expect(within(savedReportMenu).getByRole('menuitem', {
        name: 'Save Report'
      })).toHaveAttribute('aria-disabled', 'true')
      expect(within(savedReportMenu).getByRole('menuitem', {
        name: 'Revert to Saved'
      })).toHaveAttribute('aria-disabled', 'true')
      expect(within(savedReportMenu).getByRole('menuitem', {
        name: 'Export'
      })).not.toHaveAttribute('aria-disabled', 'true')
      expect(within(savedReportMenu).getByRole('menuitem', {
        name: 'Scheduled Exports'
      })).not.toHaveAttribute('aria-disabled', 'true')
      expect(within(savedReportMenu).getByRole('menuitem', {
        name: 'Share'
      })).toHaveAttribute('aria-disabled', 'true')
      expect(within(savedReportMenu).getByRole('menuitem', {
        name: 'Rename'
      })).toHaveAttribute('aria-disabled', 'true')
      expect(within(savedReportMenu).getByRole('menuitem', {
        name: 'Delete'
      })).toHaveAttribute('aria-disabled', 'true')
    })
  }
}

export const SavedReportMenuReportSelected = {
  ...SavedReports,
  play: async ({ step }) => {
    await step('Select a saved report and open the saved report menu', async() => {
      userEvent.click(await screen.findByLabelText("Daily Report"))
      userEvent.click(await screen.findByLabelText("Saved Reports Menu"))
    })

    const savedReportMenu = await screen.findByRole('menu')

    await step('Check the enabled/disabled state of menu items', async() => {
      expect(within(savedReportMenu).getByRole('menuitem', {
        name: 'Save as New Report'
      })).not.toHaveAttribute('aria-disabled', 'true')
      expect(within(savedReportMenu).getByRole('menuitem', {
        name: 'Save Report'
      })).toHaveAttribute('aria-disabled', 'true')
      expect(within(savedReportMenu).getByRole('menuitem', {
        name: 'Revert to Saved'
      })).toHaveAttribute('aria-disabled', 'true')
      expect(within(savedReportMenu).getByRole('menuitem', {
        name: 'Export'
      })).not.toHaveAttribute('aria-disabled', 'true')
      expect(within(savedReportMenu).getByRole('menuitem', {
        name: 'Scheduled Exports'
      })).not.toHaveAttribute('aria-disabled', 'true')
      expect(within(savedReportMenu).getByRole('menuitem', {
        name: 'Share'
      })).not.toHaveAttribute('aria-disabled', 'true')
      expect(within(savedReportMenu).getByRole('menuitem', {
        name: 'Rename'
      })).not.toHaveAttribute('aria-disabled', 'true')
      expect(within(savedReportMenu).getByRole('menuitem', {
        name: 'Delete'
      })).not.toHaveAttribute('aria-disabled', 'true')
    })
  }
}

export const SavedReportMenuReportSelectedWithChanges = {
  ...SavedReports,
  play: async ({ step }) => {
    await step('Select a saved report, make a change and open the saved report menu', async() => {
      userEvent.click(await screen.findByLabelText("Daily Report"))
      userEvent.click(await screen.findByLabelText("Landing Page Template"))
      userEvent.click(await screen.findByLabelText("Saved Reports Menu"))
    })

    const savedReportMenu = await screen.findByRole('menu')

    await step('Check the enabled/disabled state of menu items', async() => {
      expect(within(savedReportMenu).getByRole('menuitem', {
        name: 'Save as New Report'
      })).not.toHaveAttribute('aria-disabled', 'true')
      expect(within(savedReportMenu).getByRole('menuitem', {
        name: 'Save Report'
      })).not.toHaveAttribute('aria-disabled', 'true')
      expect(within(savedReportMenu).getByRole('menuitem', {
        name: 'Revert to Saved'
      })).not.toHaveAttribute('aria-disabled', 'true')
      expect(within(savedReportMenu).getByRole('menuitem', {
        name: 'Export'
      })).not.toHaveAttribute('aria-disabled', 'true')
      expect(within(savedReportMenu).getByRole('menuitem', {
        name: 'Scheduled Exports'
      })).not.toHaveAttribute('aria-disabled', 'true')
      expect(within(savedReportMenu).getByRole('menuitem', {
        name: 'Share'
      })).not.toHaveAttribute('aria-disabled', 'true')
      expect(within(savedReportMenu).getByRole('menuitem', {
        name: 'Rename'
      })).not.toHaveAttribute('aria-disabled', 'true')
      expect(within(savedReportMenu).getByRole('menuitem', {
        name: 'Delete'
      })).not.toHaveAttribute('aria-disabled', 'true')
    })
  }
}

export const SavedReportSaveAsNew = {
  ...SavedReports,
  play: async ({ step }) => {
    await step('Open the menu and click Save as New Report', async() => {
      userEvent.click(await screen.findByLabelText("Saved Reports Menu"))
      userEvent.click(within(await screen.findByRole('menu')).getByRole('menuitem', {
        name: 'Save as New Report'
      }))
    })

    await step('Assert a modal with the correct title exists', async() => {
      const modalTitle = await screen.findByRole('heading', {
        name: 'Save as New Report'
      })
      expect(modalTitle).toBeInTheDocument()
    })

    await step('Assert the Save button is disabled', async() => {
      expect(await screen.findByRole('button', { name: 'Save' })).toBeDisabled()
    })

    await step('Fill in the input field for "Report Name"', async() => {
      const reportNameInput = screen.getByLabelText('Report Name *')
      await userEvent.type(reportNameInput, 'My New Report')
    })

    expect(await screen.findByRole('button', { name: 'Save' })).toBeEnabled()
  }
}

export const SavedReportSave = {
  ...SavedReports,
  play: async ({ step }) => {
    await step('Select a report and update some setting', async() => {
      userEvent.click(await screen.findByLabelText("Daily Report"))
      userEvent.click(await screen.findByLabelText("Landing Page Template"))
    })

    await step('Open the menu and click Save Report', async() => {
      userEvent.click(await screen.findByLabelText("Saved Reports Menu"))
      userEvent.click(within(await screen.findByRole('menu')).getByRole('menuitem', {
        name: 'Save Report'
      }))
    })

    await step('Assert a modal with the correct title exists', async() => {
      const modalTitle = await screen.findByRole('heading', {
        name: 'Save Report'
      })
      expect(modalTitle).toBeInTheDocument()
    })

    await step('Assert the Save button is enabled', async() => {
      const saveButton = await screen.findByRole('button', { name: 'Save' })
      expect(saveButton).toBeEnabled()
    })
  }
}

export const SavedReportRevert = {
  ...SavedReports,
  play: async ({ step }) => {
    await step('Select a report', async() => {
      userEvent.click(await screen.findByLabelText("Daily Report"))
    })

    // Update a setting
    const checkbox = screen.getByRole('checkbox', { name: "Landing Page Template" })
    userEvent.click(checkbox)

    await step('Find Revert icon and click it', async() => {
      const button = await screen.findByRole('button', {
        name: 'Reset to original table settings'
      })
      expect(button).toBeInTheDocument()
      userEvent.click(button)
    })

    await step("Wait for button to disappear and assert it's gone", async() => {
      await waitFor(() => expect(screen.queryByRole('button', {
        name: 'Reset to original table settings'
      })).not.toBeInTheDocument())
      expect(checkbox).not.toBeChecked()
    })

    await step("Change a setting and use the menu version of Revert", async() => {
      userEvent.click(checkbox)
      userEvent.click(await screen.findByLabelText("Saved Reports Menu"))
      userEvent.click(within(await screen.findByRole('menu')).getByRole('menuitem', {
        name: 'Revert to Saved'
      }))
    })

    await step("Confirm revert and that menu item is disabled", async() => {
      await waitFor(() => expect(checkbox).not.toBeChecked())

      userEvent.click(await screen.findByLabelText("Saved Reports Menu"))
      expect(within(await screen.findByRole('menu')).getByRole('menuitem', {
        name: 'Revert to Saved'
      })).toHaveAttribute('aria-disabled', 'true')
    })
  }
}

export const SavedReportExport = {
  ...SavedReports,
  play: async ({ step }) => {
    await step('Open the menu and click Export', async() => {
      userEvent.click(await screen.findByLabelText("Saved Reports Menu"))
      userEvent.click(within(await screen.findByRole('menu')).getByRole('menuitem', {
        name: 'Export'
      }))
    })

    await step('Assert a modal with the correct title exists', async() => {
      const modalTitle = await screen.findByRole('heading', {
        name: 'Export Performance Report',
        level: 1
      })
      expect(modalTitle).toBeInTheDocument()
    })

    await step('Assert the Save button is enabled', async() => {
      expect(await screen.findByRole('button', { name: 'Save' })).toBeEnabled()
    })

    await step('Check input for default and clear', async() => {
      const input = screen.getByLabelText('File Name *')
      expect(input).toHaveValue('Performance')
      await userEvent.clear(input)
    })

    await step('Assert the Save button is disabled', async() => {
      expect(await screen.findByRole('button', { name: 'Save' })).toBeDisabled()
    })
  }
}

export const SavedReportScheduledExportsNoReportSelected = {
  ...SavedReports,
  play: async ({ step }) => {
    await step('Open the menu and click Scheduled Exports', async() => {
      userEvent.click(await screen.findByLabelText("Saved Reports Menu"))
      userEvent.click(within(await screen.findByRole('menu')).getByRole('menuitem', {
        name: 'Scheduled Exports'
      }))
    })

    await step('Assert a modal with the correct title exists', async() => {
      const modalTitle = await screen.findByRole('heading', {
        name: 'Scheduled Exports',
        level: 1
      })
      expect(modalTitle).toBeInTheDocument()
    })

    await step('Assert the Create New button is disabled', async() => {
      expect(await screen.findByRole('button', { name: 'Create New' })).toBeDisabled()
    })

    await step('Click the delete icon on a report and assert delete modal opens', async() => {
      const deleteButtons = screen.getAllByLabelText('Delete Scheduled Export', { exact: true })
      await userEvent.click(deleteButtons[1])
      const modalTitle = await screen.findByRole('heading', {
        name: 'Delete Report',
        level: 1
      })
      expect(modalTitle).toBeInTheDocument()
    })

    await step('Cancel Delete and click into an existing report', async() => {
      const cancelButton = await screen.findByRole('button', { name: 'Cancel' })
      await userEvent.click(cancelButton)
      const modalTitle = await screen.findByRole('heading', {
        name: 'Scheduled Exports',
        level: 1
      })
      expect(modalTitle).toBeInTheDocument()
      const dailyReport = screen.getByText('Every Weekday, 12:12 AM', { exact: true })
      await userEvent.click(dailyReport)
    })

    await step('Assert a modal with the correct title exists', async() => {
      const modalTitle = await screen.findByRole('heading', {
        name: 'Schedule "Daily Report" Export',
        level: 1
      })
      expect(modalTitle).toBeInTheDocument()
    })

    await step('Click the delete button and assert the correct modal', async() => {
      userEvent.click(await screen.findByRole('button', { name: 'Delete' }))
      const modalTitle = await screen.findByRole('heading', {
        name: 'Delete Report',
        level: 1
      })
      expect(modalTitle).toBeInTheDocument()
    })

    await step('Cancel Delete, cancel Edit and end on Scheduled Exports list', async() => {
      userEvent.click(await screen.findByRole('button', { name: 'Cancel' }))
      const modalTitle = await screen.findByRole('heading', {
        name: 'Schedule "Daily Report" Export',
        level: 1
      })
      expect(modalTitle).toBeInTheDocument()
      userEvent.click(await screen.findByRole('button', { name: 'Cancel' }))
    })
  }
}

export const SavedReportScheduledExportsWithReportSelected = {
  ...SavedReports,
  play: async ({ step }) => {
    await step('Select a report', async() => {
      userEvent.click(await screen.findByLabelText("Daily Report"))
    })

    await step('Open the menu and click Scheduled Exports', async() => {
      userEvent.click(await screen.findByLabelText("Saved Reports Menu"))
      userEvent.click(within(await screen.findByRole('menu')).getByRole('menuitem', {
        name: 'Scheduled Exports'
      }))
    })

    await step('Assert a modal with the correct title exists', async() => {
      const modalTitle = await screen.findByRole('heading', {
        name: 'Scheduled Exports',
        level: 1
      })
      expect(modalTitle).toBeInTheDocument()
    })

    await step('Assert the Create New button is enable', async() => {
      expect(await screen.findByRole('button', { name: 'Create New' })).toBeEnabled()
    })

    await step('Click Create New and assert the correct modal', async() => {
      userEvent.click(await screen.findByRole('button', { name: 'Create New' }))
      const modalTitle = await screen.findByRole('heading', {
        name: 'Schedule "Daily Report" Export',
        level: 1
      })
      expect(modalTitle).toBeInTheDocument()
    })

    await step('Assert the Delete button is disabled', async() => {
      expect(await screen.findByRole('button', { name: 'Delete' })).toBeDisabled()
    })

    const dialog = screen.getByRole('dialog')

    await step('Select "Daily" frequency and check for correct inputs', async() => {
      // Select the Frequency dropdown
      const frequencyDropdown = await screen.findByLabelText('Frequency')

      // Open the dropdown
      userEvent.click(frequencyDropdown)

      // Select an item from the dropdown
      const optionsList = await screen.findByRole('listbox')
      const weeklyOption = within(optionsList).getByText('Daily')
      userEvent.click(weeklyOption)

      // Assert that inputs exist
      expect(within(dialog).getByLabelText('Time')).toBeInTheDocument()

      // Assert inputs do not exist
      expect(within(dialog).queryByLabelText('# Weeks')).not.toBeInTheDocument()
      expect(within(dialog).queryByLabelText('Day')).not.toBeInTheDocument()
      expect(within(dialog).queryByLabelText('# Day')).not.toBeInTheDocument()
    })

    await step('Select "Every Weekday" frequency and check for correct inputs', async() => {
      // Select the Frequency dropdown
      const frequencyDropdown = await screen.findByLabelText('Frequency')

      // Open the dropdown
      userEvent.click(frequencyDropdown)

      // Select an item from the dropdown
      const optionsList = await screen.findByRole('listbox')
      const weeklyOption = within(optionsList).getByText('Every Weekday')
      userEvent.click(weeklyOption)

      // Assert that inputs exist
      expect(within(dialog).getByLabelText('Time')).toBeInTheDocument()

      // Assert inputs do not exist
      expect(within(dialog).queryByLabelText('# Weeks')).not.toBeInTheDocument()
      expect(within(dialog).queryByLabelText('Day')).not.toBeInTheDocument()
      expect(within(dialog).queryByLabelText('# Day')).not.toBeInTheDocument()
    })

    await step('Select "Every [X] Weeks on [X] Day" frequency and check for correct inputs', async() => {
      // Select the Frequency dropdown
      const frequencyDropdown = await screen.findByLabelText('Frequency')

      // Open the dropdown
      userEvent.click(frequencyDropdown)

      // Select an item from the dropdown
      const optionsList = await screen.findByRole('listbox')
      const weeklyOption = within(optionsList).getByText('Every [X] Weeks on [X] Day')
      userEvent.click(weeklyOption)

      // Assert that inputs exist
      await waitFor(() => expect(within(dialog).getByLabelText('# Weeks')).toBeInTheDocument())
      expect(within(dialog).getByLabelText('Day')).toBeInTheDocument()
      expect(within(dialog).getByLabelText('Time')).toBeInTheDocument()

      // Assert inputs do not exist
      expect(within(dialog).queryByLabelText('# Day')).not.toBeInTheDocument()
    })

    await step('Select "Every [X] Day of the Month" frequency and check for correct inputs', async() => {
      // Select the Frequency dropdown
      const frequencyDropdown = await screen.findByLabelText('Frequency')

      // Open the dropdown
      userEvent.click(frequencyDropdown)

      // Select an item from the dropdown
      const optionsList = await screen.findByRole('listbox')
      const weeklyOption = within(optionsList).getByText('Every [X] Day of the Month')
      userEvent.click(weeklyOption)

      // Assert that inputs exist
      await waitFor(() => expect(within(dialog).getByLabelText('# Day')).toBeInTheDocument())
      expect(within(dialog).getByLabelText('Time')).toBeInTheDocument()

      // Assert inputs do not exist
      expect(within(dialog).queryByLabelText('# Weeks')).not.toBeInTheDocument()
      expect(within(dialog).queryByLabelText('Day')).not.toBeInTheDocument()
    })
  }
}

export const SavedReportShare = {
  ...SavedReports,
  play: async ({ step }) => {
    await step('Select a report', async() => {
      userEvent.click(await screen.findByLabelText("Daily Report"))
    })

    await step('Open the menu and click Share', async() => {
      userEvent.click(await screen.findByLabelText("Saved Reports Menu"))
      userEvent.click(within(await screen.findByRole('menu')).getByRole('menuitem', {
        name: 'Share'
      }))
    })

    await step('Assert the clipboard contains the copied url and params', async() => {
      const clipboardText = await navigator.clipboard.readText()
      expect(clipboardText).toBe("http://localhost:6006/performance/quality?tableSettings=%7B%22visibleColumns%22%3A%5B%22clientId%22%2C%22billableLeadCount%22%2C%22filteredLeadCount%22%5D%2C%22subtotalColumns%22%3A%5B%5D%2C%22sortColumn%22%3A%22clientId%22%2C%22sortOrder%22%3A1%2C%22reportingMode%22%3A%22cohort%22%2C%22rowFilters%22%3A%5B%7B%22field%22%3A%22source%22%2C%22values%22%3A%5B%22facebook%22%5D%7D%5D%2C%22columnFilters%22%3A%7B%22filteredLeadCount%22%3A%7B%22operator%22%3A%22greater-than%22%2C%22value%22%3A%5B%221%22%5D%7D%7D%2C%22showAdjusted%22%3Afalse%2C%22savedReportName%22%3A%22Daily+Report%22%2C%22savedReportId%22%3A%221%22%7D&dateRange=%22Last+Year%22")
    })
  }
}

export const SavedReportRename = {
  ...SavedReports,
  play: async ({ step }) => {
    await step('Select a report', async() => {
      userEvent.click(await screen.findByLabelText("Daily Report"))
    })

    await step('Open the menu and click Rename', async() => {
      userEvent.click(await screen.findByLabelText("Saved Reports Menu"))
      userEvent.click(within(await screen.findByRole('menu')).getByRole('menuitem', {
        name: 'Rename'
      }))
    })

    await step('Assert a modal with the correct title exists', async() => {
      const modalTitle = await screen.findByRole('heading', {
        name: 'Rename Report',
        level: 1
      })
      expect(modalTitle).toBeInTheDocument()
    })

    await step('Assert the Save button is disabled', async() => {
      expect(await screen.findByRole('button', { name: 'Save' })).toBeDisabled()
    })

    await step('Check input for default and clear', async() => {
      const input = screen.getByLabelText('Report Name *')
      expect(input).toHaveValue('Daily Report')
      await userEvent.clear(input)
    })

    await step('Assert the Save button is disabled', async() => {
      expect(await screen.findByRole('button', { name: 'Save' })).toBeDisabled()
    })

    await step('Enter valid report name', async() => {
      const input = screen.getByLabelText('Report Name *')
      expect(input).toHaveValue('')
      await userEvent.type(input, 'Real Report Name')
    })

    await step('Assert the Save button is enabled', async() => {
      expect(await screen.findByRole('button', { name: 'Save' })).toBeEnabled()
    })
  }
}

export const SavedReportDelete = {
  ...SavedReports,
  play: async ({ step }) => {
    await step('Select a report', async() => {
      userEvent.click(await screen.findByLabelText("Daily Report"))
    })

    await step('Open the menu and click Delete', async() => {
      userEvent.click(await screen.findByLabelText("Saved Reports Menu"))
      userEvent.click(within(await screen.findByRole('menu')).getByRole('menuitem', {
        name: 'Delete'
      }))
    })

    await step('Assert a modal with the correct title exists', async() => {
      const modalTitle = await screen.findByRole('heading', {
        name: 'Delete Report',
        level: 1
      })
      expect(modalTitle).toBeInTheDocument()
    })

    await step('Assert the Delete button is enabled', async() => {
      expect(await screen.findByRole('button', { name: 'Delete' })).toBeEnabled()
    })
  }
}

export const SidebarCollapsedMouseOver = {
  ...Default,
  play: async ({ canvasElement }) => {
    const canvas = within(canvasElement)
    await waitFor(async () => {
      await userEvent.hover(canvas.getByTestId('TableChartOutlinedIcon'))
    })
  }
}
