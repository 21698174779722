import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import COLORS from 'lib/colors'
import CloseIcon from '@mui/icons-material/Close'
import { ReactNode, useCallback } from 'react'
import { SxProps } from "@mui/system"
import { LoadingButton } from '@mui/lab'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'



function PaperComponentWithButtons({onClickPrev, onClickNext, ...props}) {
  return (
    <Stack direction='row' alignItems='center'>
      {onClickPrev &&
        <IconButton onClick={onClickPrev} sx={{ height: '48px', width: '48px', backgroundColor: COLORS.regalBlue, color: 'white', '&:hover': { backgroundColor: COLORS.frenchBlue }, }}>
          <ArrowBackIcon />
        </IconButton>
      }

      <Paper sx={{ mx: '12px !important' }} {...props} />

      {onClickNext &&
        <IconButton onClick={onClickNext} sx={{ height: '48px', width: '48px', backgroundColor: COLORS.regalBlue, color: 'white', '&:hover': { backgroundColor: COLORS.frenchBlue }, }}>
          <ArrowForwardIcon />
        </IconButton>
      }
    </Stack>
  );
}

function ButtonWithOptionalTooltip({ tooltip, onConfirm, loading, disabled, confirmText }) {
  const button = (
    <LoadingButton
      variant="contained"
      onClick={onConfirm}
      color="primary"
      loading={loading}
      disabled={disabled}
    >
      {confirmText}
    </LoadingButton>
  )

  if (!tooltip) {
    return button;
  }

  return (
    <Tooltip title={tooltip}>
      <span>{button}</span>
    </Tooltip>
  )
}

interface TransitionProps {
  onExited: (() => void)
}

const StandardDialog = ({
    title,
    description,
    onCancel,
    onConfirm,
    onClose,
    cancelText = 'Cancel',
    confirmText = 'Save',
    tooltip = '',
    footer,
    maxWidth,
    children,
    open = true,
    loading = false,
    disabled = false,
    sx,
    transitionProps,
    onClickPrev = undefined,
    onClickNext = undefined,
  } : {
    title: string,
    description?: string | ReactNode,
    onCancel?: (() => void),
    onConfirm?: (() => void),
    onClose?: (() => void),
    cancelText?: string,
    confirmText?: string,
    tooltip?: string,
    footer?: ReactNode,
    maxWidth?: string,
    children?: ReactNode,
    open?: boolean,
    loading?: boolean,
    disabled?: boolean,
    sx?: SxProps,
    transitionProps?: TransitionProps,
    onClickPrev?: (() => void) | undefined,
    onClickNext?: (() => void) | undefined,
  }) => {
    const PaperComponent = useCallback((props) => PaperComponentWithButtons({onClickPrev: onClickPrev, onClickNext: onClickNext, ...props}), [onClickNext, onClickPrev])

    return (
    <Dialog aria-labelledby="modal-title" open={open} sx={sx} TransitionProps={transitionProps} fullWidth PaperProps={{ component: (onClickPrev || onClickNext) ? PaperComponent : undefined, sx:{ maxWidth: maxWidth ? maxWidth : null }}}>
      <DialogTitle id="modal-title" sx={{color:'unset', fontWeight:'unset', pr: 4}}>
        <Stack rowGap={2}>
          <Stack direction='row' alignItems='center' justifyContent='space-between' columnGap={2} flexWrap='nowrap'>
            <Typography variant='h1'>{title}</Typography>
            {onCancel &&
              <IconButton aria-label='Close Dialog' sx={{ my: -.5, mr: -1, padding: .5, color: COLORS.charcoal }} onClick={onClose || onCancel} size='small'>
                <CloseIcon/>
              </IconButton>
            }
          </Stack>
          {description &&
          <Typography variant='modal'>{description}</Typography>
          }
        </Stack>
      </DialogTitle>
      {children &&
        <>
          <Divider light sx={{ mx: 4 }}/>
          <DialogContent sx={{ py: 3 }}>
            {children}
          </DialogContent>
        </>
      }
      <Divider light sx={{ mx: 4 }}/>
      <DialogActions sx={{ py: 3, px: 4 }}>
        <Grid container direction='row' justifyContent='space-between' columnGap={2} flexWrap='nowrap'>
          <Grid item sx={{ alignSelf: 'center' }}>
            {footer}
          </Grid>
          <Grid item sx={{ alignSelf: 'flex-end' }}>
            <Grid container columnGap={2} flexWrap='nowrap'>
              {onCancel &&
                <Grid item>
                  <Button color='secondary' disableElevation variant='text' onClick={onCancel} disabled={loading}>
                    {cancelText}
                  </Button>
                </Grid>
              }
              {onConfirm &&
                <Grid item>
                  <ButtonWithOptionalTooltip tooltip={tooltip} onConfirm={onConfirm} loading={loading} disabled={disabled} confirmText={confirmText} />
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default StandardDialog
