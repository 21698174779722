import { createContext, useContext, useState, useCallback, ReactNode } from 'react'

export type NotificationVariant = 'notice' | 'alert'

export interface Notification {
  message: string,
  variant: NotificationVariant,
  actionComponent?: ReactNode,
  isPersistent?: boolean,
}

interface NotificationsContextProps {
  notifications: Notification[],
  addNotification: (notification : Notification) => void,
  removeNotification: (index : number) => void,
  clearNotifications: () => void
}

export const notificationsContext = createContext<NotificationsContextProps>({
  notifications: [],
  addNotification: () => { return },
  removeNotification: () => { return },
  clearNotifications: () => { return },
})

interface NotificationsProviderProps {
  initialNotifications?: Notification[],
  children: ReactNode,
}

export default function NotificationsProvider({ initialNotifications = [], children } : NotificationsProviderProps) {
  const [notifications, setNotifications] = useState(initialNotifications)

  const removeNotification = useCallback((index) => {
    setNotifications(prevNotifications => prevNotifications.filter((_notification, i) => i !== index))
  }, [])

  const addNotification = useCallback((notification) => {
    setNotifications(prevNotifications => [...prevNotifications, notification])
  }, [])

  const clearNotifications = useCallback(() => {
    setNotifications([])
  }, [])

  return (
    <notificationsContext.Provider value={{ notifications, addNotification, removeNotification, clearNotifications }}>
      {children}
    </notificationsContext.Provider>
  )
}

export function useNotifications() {
  return useContext(notificationsContext)
}
